exports.components = {
  "component---src-pages-2022-conference-tsx": () => import("./../../../src/pages/2022/conference.tsx" /* webpackChunkName: "component---src-pages-2022-conference-tsx" */),
  "component---src-pages-2022-team-tsx": () => import("./../../../src/pages/2022/team.tsx" /* webpackChunkName: "component---src-pages-2022-team-tsx" */),
  "component---src-pages-2022-workshops-tsx": () => import("./../../../src/pages/2022/workshops.tsx" /* webpackChunkName: "component---src-pages-2022-workshops-tsx" */),
  "component---src-pages-2023-conference-tsx": () => import("./../../../src/pages/2023/conference.tsx" /* webpackChunkName: "component---src-pages-2023-conference-tsx" */),
  "component---src-pages-2023-team-tsx": () => import("./../../../src/pages/2023/team.tsx" /* webpackChunkName: "component---src-pages-2023-team-tsx" */),
  "component---src-pages-2023-workshops-tsx": () => import("./../../../src/pages/2023/workshops.tsx" /* webpackChunkName: "component---src-pages-2023-workshops-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-archive-tsx": () => import("./../../../src/pages/archive.tsx" /* webpackChunkName: "component---src-pages-archive-tsx" */),
  "component---src-pages-conference-tsx": () => import("./../../../src/pages/conference.tsx" /* webpackChunkName: "component---src-pages-conference-tsx" */),
  "component---src-pages-hackathon-tsx": () => import("./../../../src/pages/hackathon.tsx" /* webpackChunkName: "component---src-pages-hackathon-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-rules-tsx": () => import("./../../../src/pages/rules.tsx" /* webpackChunkName: "component---src-pages-rules-tsx" */),
  "component---src-pages-schedule-tsx": () => import("./../../../src/pages/schedule.tsx" /* webpackChunkName: "component---src-pages-schedule-tsx" */),
  "component---src-pages-summer-school-tsx": () => import("./../../../src/pages/summer_school.tsx" /* webpackChunkName: "component---src-pages-summer-school-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-workshops-tsx": () => import("./../../../src/pages/workshops.tsx" /* webpackChunkName: "component---src-pages-workshops-tsx" */)
}

